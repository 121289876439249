import { MenuItems } from "./MenuItems";
import React from 'react';

import { Nav, Logo, NavMenu, Img, BurgerMenu, Drawer } from './navbarElements'
import { useEffect, useState } from "react";


const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset

            if (scrollTop > 0) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    return (
        <Nav isScrolled={isScrolled}>
            <Logo href={'/'}>
                <Img src="/logo.png" />
            </Logo>
            <NavMenu isScrolled={isScrolled}>
                {MenuItems.map((item, index) => {
                    return (
                        <li key={index}>
                            <a href={item.url}>
                                {item.title}
                            </a>
                        </li>

                    )
                })}
            </NavMenu>
            <BurgerMenu onClick={() => setIsDrawerOpen(!isDrawerOpen)} isOpen={isDrawerOpen}>
                <span />
                <span />
                <span />
            </BurgerMenu>
            <Drawer isOpen={isDrawerOpen}>
                {MenuItems.map((item, index) => (
                    <a href={item.url} key={index} onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                        {item.title}
                    </a>
                ))}
            </Drawer>
        </Nav>
    )
}

export default Navbar;