import React from "react";
import { CopyRight,} from './footerElements'



const Footer = () => {
    return (
        <>
            <CopyRight>
                <span>Copyright © 2023 Onit Soft</span>
             </CopyRight>
        </>
    )
}

export default Footer


