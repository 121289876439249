export const MenuItems = [
    {
        title : "Strona główna",
        url : "/",
        cName : "nav-links"
    },
    {
        title : "Inwestycja",
        url : "/#about",
        cName : "nav-links"
    },
    {
        title : "Kontakt",
        url : "/#contact",
        cName : "nav-links"
    },
    // {
    //     title : "Lokale",
    //     url : "/",
    //     cName : "nav-links"
    // },
    // {
    //     title : "Dlaczego My",
    //     url : "/#our",
    //     cName : "nav-links"
    // },
    
]