import React from "react";
import Navbar from "./components/Navbar"
import Home from "./components/Home"
import Footer from "./components/Footer"
import Contact from "./components/Contact"

function App() {
  return (
    <>
      <Navbar/>
      <Home/>
      <Contact/>
      <Footer/>
    </>
  );
}

export default App;
