import React, { useState } from 'react';
import {ContactCol, Row, Coc, ContactUs, Location, Coc1, UserIcon, EnvelopeIcon, MapIcon } from './contactElements'

// import API from '../../api'
import { useForm, Controller } from 'react-hook-form'

const ContactForm = () => {
  const {
    control,
    register,
    handleSubmit
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    }
  })

  const onSubmit = (data) => {
    data.category = 'contact request'
    // API.sendContactRequest(data)    
      .then((res) => console.log(res))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
     
      <Controller
        control={control}
        name={'name'}
        render={({ field, onChange }) => (
          <input
            type="text"
            name="name"
            placeholder="Twoje imię i nazwisko"
            {...register('name')}
          />
        )}
      />
      <Controller
        control={control}
        name={'email'}
        render={({ field, onChange }) => (
          <input
            type="email"
            name="email"
            placeholder="Twój adres e-mail"
            {...register('email')}
          />
        )}
      />
      <Controller
        control={control}
        name={'phone'}
        render={({ field, onChange }) => (
          <input
            type="text"
            name="phone"
            placeholder="Twój numer telefonu"
            {...register('phone')}
          />
        )}
      />
      <Controller
        control={control}
        name={'subject'}
        render={({ field, onChange }) => (
          <input
            type="text"
            name="subject"
            placeholder="Temat Twojej wiadomości "
            {...register('subject')}
          />
        )}
      />
      <Controller
        control={control}
        name={'message'}
        render={({ field, onChange }) => (
          <textarea
            rows="8"
            name="message"
            placeholder="Wiadomość"
            {...register('message')}
          />
        )}
      />
      <button type="submit">Wyślij</button>
    </form>
  );
};


const Contact = () => {
  return (
    <>
     

      <ContactUs id='contact'>
        <Row>
          <ContactCol>
            <h2>SKONTAKTUJ SIĘ Z NAMI</h2>
            <Coc>
                <UserIcon/>
              <span>
                <h5>Robert Matracki</h5>
                <p>robert.matracki@askdevelopment.pl</p>
                <p>+48 606-647-277</p>
              </span>
            </Coc>
            <Coc>
                <UserIcon/>
              <span>
                <h5>Zbigniew Kowalski</h5>
                <p>zbigniew.kowalski@askdevelopment.pl</p>
                <p>+48 600-881-188</p>
              </span>
            </Coc>

            <Coc>
            <EnvelopeIcon/>
              <span>
                <h5>Cystersów 13A/1</h5>
                <p>31-553 Kraków, Polska, PL</p>
              </span>
            </Coc>
            <Coc>
                <MapIcon/>
              <span>
                <h5>biuro@askdevelompent.pl</h5>
                <p>Wyślij do nas wiadomość e-mail</p>
              </span>
              <span></span>
            </Coc>
          </ContactCol>
          <ContactCol>
            <ContactForm></ContactForm>
          </ContactCol>
        </Row>
      </ContactUs>

      <Location>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10283.879202010969!2d20.1141754!3d49.8805937!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716152c817bea5d%3A0x7afa20a36039a3aa!2sKrzy%C5%BC%20milenijny%20na%20Skrzynce!5e0!3m2!1spl!2spl!4v1697529401789!5m2!1spl!2spl"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Location>

      
    </>
  )
}
export default Contact

