import styled from "styled-components"


export const CopyRight = styled.div`
  text-align: center;
  padding: 20px;
  background: #a1a3b3;
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  @media screen and (max-width: 800px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
`