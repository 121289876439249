import React from "react";
import { Header, TextBox, HeroContent, About, Container, Photos, AboutContent, LookFor, Our, Row, OurCol, Ruo } from "./homeElements";

import styled, { keyframes } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import img7 from '../../assets/img7.jpg';
import img1 from '../../assets/img1.jpg';
import img4 from '../../assets/img4.jpg';
import img6 from '../../assets/img6.jpg';

const ParallaxContainer = styled.div`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 1px;
`;

const Background = styled.div`
  background-image: url(${img7});
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
`;



const ParallaxEffect = () => (
  <ParallaxContainer>
    <Background />
  </ParallaxContainer>
);



const slideUpEnter = keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100px);
    }
`;

const SlideEnter = styled.div`
   
   &.slide-enter {
        animation: ${slideUpEnter} 2s ease-in-out forwards;
    }
`;

function AnimatedText() {
    return (
        <CSSTransition in={true} timeout={2000} classNames="slide">
            
        </CSSTransition>
    );
}



const Home = () => {
    
    
    return (
        <>

        {/* Main */}
          <Header>
                <TextBox>
                    <HeroContent>
                        <SlideEnter className="slide-enter">
                            <h2>Wzgórze Milenijne </h2>
                            <p> Witamy na Wzgórzu Milenijnym – miejscu, gdzie nowoczesność spotyka się z naturą. Nasze nowe osiedle domków oferuje przyszłym mieszkańcom nie tylko komfortowe i nowoczesne przestrzenie mieszkalne, ale również bliskość natury i spokój z dala od miejskiego zgiełku. Wzgórze Milenijne  to inwestycja, która jest dopiero w trakcie realizacji, co daje przyszłym właścicielom możliwość personalizacji swojego wymarzonego domu.</p>
                        </SlideEnter>
                    </HeroContent>
                </TextBox>
            </Header>  

        {/* About */}
        
        <About id="about">
            <Container>
                <Photos>
                    <div />
                </Photos>
                <AboutContent>
                    <h1>WZGÓRZE MILENIJNE</h1>
                    <p>Wzgórze Milenijne stanowi miejsce, gdzie nowoczesność spleciona jest z pięknem przyrody, oferując przyszłym mieszkańcom komfortowe, nowoczesne przestrzenie mieszkalne w otoczeniu zieleni oraz spokoju, oddalone od miejskiego zgiełku. To unikatowa inwestycja, obecnie w trakcie realizacji, umożliwiająca przyszłym właścicielom personalizację ich wymarzonego domu.
                       Każdy dom na Wzgórzu Milenijnym  został starannie zaprojektowany z myślą o komforcie i funkcjonalności, łącząc w sobie nowoczesne rozwiązania technologiczne z eleganckim, ponadczasowym designem. Projekt odznacza się nie tylko wysokim standardem, ale także przemyślaną architekturą oraz dbałością o detale, które tworzą niepowtarzalny charakter tego miejsca.
                       Osiedle Wzgórze Milenijne może pochwalić się dobrze rozwiniętą infrastrukturą, co gwarantuje łatwy dostęp do lokalnych atrakcji, sklepów, placówek edukacyjnych oraz terenów rekreacyjnych i zielonych. Jest to inwestycja, która zaspokoi oczekiwania nawet najbardziej wymagających klientów, pragnących połączyć komfort życia w mieście z zaletami bliskiego kontaktu z naturą.</p>
                       <LookFor>
                            <a href="#contact">Skontaktuj się z nami</a>
                       </LookFor>
                </AboutContent>
            </Container>
        </About>

        {/* Our */}
        
        <ParallaxEffect>
        
        </ParallaxEffect>

        <Our id="our">
            <h1>Unikatowość naszej inwestycji</h1>
            <p>Nasza inwestycja stanowi wyjątkową perłę na rynku nieruchomości, oferując unikatowe połączenie nowoczesnego designu, zaawansowanych, ekologicznych technologii oraz ekskluzywnej lokalizacji w bliskim kontakcie z naturą, co razem tworzy propozycję bez precedensu dla najbardziej wymagających klientów.</p>
            <Row>
              <OurCol>
                      <img src={img1} />
                    <h3>Innowacyjna architektura</h3>
                    {/* <p>Nasze osiedle charakteryzuje się unikatowym, nowoczesnym designem, który łączy w sobie elegancję i funkcjonalność, tworząc przestrzeń idealną do życia.</p> */}
              </OurCol>
              <OurCol>
                      <img src={img6} />
                    <h3>Ekologiczny standard</h3>
                    {/* <p>Świadomie wykorzystujemy ekologiczne technologie i materiały najwyższej jakości, aby zapewnić trwałość i energooszczędność każdemu z naszych domów, dbając jednocześnie o środowisko.</p> */}
              </OurCol>
              <OurCol>
                      <img src={img4} />
                      <h3>Zacisze przyrody</h3>
                    {/* <p>Nasza inwestycja znajduje się w spokojnym i malowniczym miejscu, otoczonym przez naturę, co gwarantuje przyszłym mieszkańcom prywatność, spokój i niepowtarzalny widok każdego dnia.</p> */}
              </OurCol>
            </Row>
        </Our>

         {/* Information bar */}

         <Ruo>
             <h1>Atutem inwestycji jest również lokalizacja</h1>
            <p>Lokalizacja Wzgórza Milenijnego jest jednym z kluczowych atutów tej inwestycji, idealnie wpisując się w oczekiwania osób ceniących spokój, a jednocześnie chcących mieć szybki dostęp do pełnej oferty miejskiej. Osiedle znajduje się w strategicznym punkcie, zapewniając łatwą komunikację z centrum miasta oraz głównymi arteriami komunikacyjnymi. Otoczone jest zielenią i terenami rekreacyjnymi, co stanowi doskonałe miejsce do życia dla rodzin z dziećmi oraz wszystkich, którzy pragną aktywnie spędzać czas na świeżym powietrzu. Bliskość sklepów, placówek edukacyjnych oraz punktów usługowych sprawia, że codzienne życie jest wygodne i pozbawione konieczności długich dojazdów. Lokalizacja Wzgórza Milenijnego to gwarancja komfortu i jakości życia w harmonii z naturą, bez rezygnacji z uroków miejskiego stylu życia.</p>
         </Ruo>
            

            {/* Contact */}

              
        </>
        
    )
}

export default Home