import styled from 'styled-components'
import {FaUser} from 'react-icons/fa'
import {FaEnvelope} from 'react-icons/fa'
import {FaMapMarkerAlt} from 'react-icons/fa'

const icon = `
    margin-right: 1rem;
    font-size: 3rem;

    & > path {
        fill: #93695a
    }
`

export const MapIcon = styled(FaMapMarkerAlt)`
    ${icon}
`

export const EnvelopeIcon = styled(FaEnvelope)`
    ${icon}
`

export const UserIcon = styled(FaUser)`
    ${icon}
`

export const ContactUs = styled.div`
    width: 80%;
    margin: auto;
`

export const Row = styled.div`
    margin-top: 5%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
` 

export const ContactCol = styled.div`
    flex-basis: 48%;
    margin-bottom: 30px;


    & h2{
        color: #93695a;
        position: relative;
        top: -40px;
        padding-top: 30px;
    }

    
    & input{
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
    /* position: relative;
	right: -600px;
    top: -100px; */
    }

    & textarea{
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
    /* position: relative;
    right: -600px;
    top: -100px; */
    }


    & button{
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #93695a ;
    padding: 12px 34px;
    font-size: 13px;
    background: #93695a ;
    position: relative;
    cursor: pointer;
    /* right: -600px;
    top: -100px; */
    transition: all .3s ease-in-out;
    }

    & button:hover{
        border: 1px solid #93695a;
        background: #fff;
        cursor: pointer;
        color: #93695a;
    }

`


export const Coc = styled.div`
     display: flex;
     align-items: center;
     margin: 20px;
     margin-bottom: 30px;

     & input{
         width: 100%;
         padding: 15px;
         margin-bottom: 17px;
         outline: none;
         border: 1px solid #ccc;
     }


     & p{
        padding: .2rem 0;
     }

     & h5{
         font-size: 20px;
         margin-bottom: 5px;
         color: #555;
         font-weight: 400;
     }

     & a{
        display: inline-block;
        text-decoration: none;
        color: #fff;
        border: 1px solid #c0a36a;
        padding: 12px 34px;
        font-size: 13px;
        background: #c0a36a;
        position: relative;
        cursor: pointer;
        /* right: -600px;
        top: -100px; */
        transition: all .3s ease-in-out;
     }

     & a:hover{
        border: 1px solid #c0a36a;
        background: #fff;
        cursor: pointer;
        color: #c0a36a;
    }
`

 export const Location = styled.div`
     width: 100vw; 
     margin: auto; 
 


        & iframe{
             width: 100%; 
            height: 450px; 
        }

        
     `


export const Coc1 = styled.div`

& a{
        display: inline-block;
        text-decoration: none;
        color: #fff;
        border: 1px solid #c0a36a;
        padding: 12px 34px;
        font-size: 13px;
        background: #c0a36a;
        position: relative;
        cursor: pointer;
        /* right: -600px;
        top: -100px; */
        transition: all .3s ease-in-out;
        min-width: 187px;
        text-align:center;
        
     }

     & a:hover{
        border: 1px solid #c0a36a;
        background: #fff;
        cursor: pointer;
        color: #c0a36a;
    }
`
