import styled from "styled-components"
import img3 from '../../assets/img3.jpg';
import img5 from '../../assets/img5.jpg';


export const Header = styled.div`
min-height: 100vh;
width: 100%;
background-image: linear-gradient(rgba(4,9,30,0.1),rgba(4,9,30,0.3)), url(${img3});
background-position: center;
position:  relative;
background-size: cover;
`

export const TextBox = styled.div`
width: 90%;
color: #fff;
position: absolute;
top: 60%;
left: 50%;
transform:translate(-50%,-50%);
text-align: left;
`

export const HeroContent = styled.div`
    margin-bottom: 60px;
    position: absolute;
    max-width: 30rem;
    top: -5rem;

& h2{
    color: #93695a;
    display: flex;
    margin-bottom: 20px;
    font-size: 2.5rem;
    line-height: 1;
}

& p{
    display: flex;
    color: #fff;
    font-size: 1rem;
    line-height: 1.8;
    padding-left: 15px;
    border-left: 1px solid;
    margin-bottom: 30px;
    /* text-shadow: rgba(69,69,69, 0.9) 1px 0 10px; */
    letter-spacing: .06rem;
    

}
`



export const About = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
    

    @media (max-width: 720px) {
            flex-direction: column;
        }
`

export const Container = styled.div`
    padding: 5rem 1rem;
    display: flex;
    width: 100vw;

    @media (max-width: 1024px) {
        padding: 5rem 1rem;
    }
`

export const Photos = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    height: 700px; 
    margin: 0 2rem;
    /* box-shadow: 4px 0px 6px 1px rgba(20, 30, 29, .1); */
    /* border-radius: 1rem; */
    

    & > div {
        display: flex;
        position:relative;
        left: 30px;
        width: 95%;
        height: 95%;
        margin: 2.5% 2.5%;
        /* border-radius: .66rem; */
        background-image: url(${img5});
        background-size: cover;
        background-position: center;
        transform-origin: center center;
        
    }

    @media (max-width: 1024px) {
        display: none;
    }
`

export const AboutContent= styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 5rem;

    & > h1 {
        text-align: center;
        margin-bottom: 0.5rem;
        font-size: 2.5rem;
        color: #a1a3b3;
        padding: 5px 20px;
    }

    & > p {
        text-align: justify;
        margin-bottom: 1rem;
        color: rgb(69, 69, 69);
        word-spacing: -2px;
        font-size: 1.1rem;
        font-weight: lighter;
        line-height: 2rem;
        margin-bottom: 4.5rem;
    }
`

export const LookFor = styled.div`

    & > a {
        text-decoration: none;
        color:  #fff ;
        border: 1px solid  #a1a3b3;
        padding: 12px 34px;
        font-size: 13px;
        background: #a1a3b3;
        position: relative;
        cursor: pointer;
        width: fit-content;
        margin-top: 30px;
    }

    & a:hover{
        color:  #a1a3b3;
        border: 1px solid   #a1a3b3;
        background: transparent;
        cursor: pointer;    
    }
`

export const Our = styled.div`
    width: 85%;
    margin: auto;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 3rem;

    & > p {
        padding-top: 2rem;
        font-size: 19px;
        line-height: 30px;
        color: #93695a;
    }

    & h1{
        color: #93695a;
        font-size: 2rem;
    }

`

export const OurCol = styled.div`
    flex-basis: 31%;
    border-radius: 10px;
    text-align: center;


    & img{
        width: 100%;
        height: 30rem;
        object-fit: cover;
        /* background-size: cover; */
        
    

        @media (max-width: 1024px) {
            max-height: 300px;
            height:300px;
            
        }
    }

    & p{
        padding: 0;
        /* font-size: 19px; */
        color: #93695a;
        font-size: 15px;
        
    }

    & h3{
        font-size: 1.2rem;
        margin-top: 16px;
        margin-bottom: 10px;
        text-align: center;
        color: #93695a;
       
    }
`
export const Row = styled.div`
    margin-top: 5%;
    justify-content: space-between;
    display: flex;

    @media (max-width: 1024px) {
        flex-direction: column;

        & > * {
            margin-top: 1rem;
        }
    }
`

export const Ruo = styled.div`
    flex-basis: 31%;
    /* border-radius: 300px; */
    text-align: center;
    margin-top: 5rem;
    padding: 2rem;
    background-color:#a1a3b3;

    & > p {
        font-size: 19px;
        line-height: 35px;
        color: #fff;
    }

    & h1 {
        font-size: 2.5rem;
        color: #fff;
        line-height: 8rem;
    }

`


